import * as yup from 'yup'

export default function useValidations () {
  const { t } = useI18n()

  const emailValidation = yup.string().matches(/^[\w][\w.-]*[\w]@[\w.-]+\.\w{2,}$/, t('validation.emailInvalid'))

  return {
    emailValidation,
  }
}
