<script lang="ts" setup>
import { useField } from 'vee-validate'
import type { SelectProps as Props } from './BaseSelect.vue'

const props = defineProps<Props>()
const { value, errorMessage } = useField(props.name!)
</script>

<template>
  <BaseSelect
    v-bind="props"
    v-model="value"
    :error="errorMessage"
  />
</template>
