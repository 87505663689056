<script setup lang="ts">
const { t } = useI18n()
const { navigationItems } = useNavigation()
</script>

<template>
  <nav
    flex justify-between items-center text-sm
    xl="mb-0 p-0 gap-8"
  >
    <ul
      hidden
      xl="flex gap-2 justify-between items-center text-app-text font-medium w-full"
    >
      <li
        v-for="item in navigationItems.filter((item) => !item.isMobile)"
        :key="item.key"
        xl:inline-block
        whitespace-nowrap
      >
        <NuxtLink :to="item.href" :target="item.target">
          {{ t(item.key) }}
        </NuxtLink>
      </li>
    </ul>
    <LangSwitcher />
    <div
      flex items-center gap-4
      lg:hidden
    >
      <NuxtImg
        h-10
        src="/images/logo/CZKC_LOGO_BLUE.svg"
        alt="CZKC Logo"
      />
      <span text-xs font-medium text-app-accent-secondary>
        TESTNET FAUCET
      </span>
    </div>
    <div v-auto-animate>
      <HeadlessPopover
        v-slot="{ open, close }"
        flex xl:hidden
        md:ml-auto
      >
        <HeadlessPopoverButton id="headless-popover-navigation">
          <UnoIcon class="i-heroicons-bars-3 text-app-primary size-6" aria-hidden="true" />
        </HeadlessPopoverButton>
        <div v-if="open" bg="app-primary/85" z-20 fixed top-0 left-0 w-100dvw h-100dvh />
        <HeadlessPopoverPanel
          duration-750
          transition-all fixed z-50 top-0 right-0 bg-app-secondary flex flex-col justify-between w="3/4" h-100dvh
          px-6 py-8
          md="w-1/2"
          xl:hidden
        >
          <div flex flex-col gap-8>
            <button
              ml-auto pt-2
              lg:pt-0
              @click="close"
            >
              <UnoIcon class="i-heroicons-x-mark size-6" aria-hidden="true" />
            </button>
            <ul flex flex-col gap-8 text-app-text font-medium>
              <li
                v-for="item in navigationItems"
                :key="item.key"
                @click="close"
              >
                <NuxtLink
                  text-lg
                  :to="item.href"
                  :target="item.target"
                >
                  {{ t(item.key) }}
                </NuxtLink>
              </li>
            </ul>
          </div>
          <LangSwitcherMobile />
        </HeadlessPopoverPanel>
      </HeadlessPopover>
    </div>
  </nav>
</template>
