<script lang="ts" setup>
const { t } = useI18n()

const descriptions = [
  {
    icon: '/images/Workspaces.svg',
    key: 'description.item1',
  },
  {
    icon: '/images/ElectricBolt.svg',
    key: 'description.item2',
  },
  {
    icon: '/images/FrameSource.svg',
    key: 'description.item3',
  },
]

const learnMorePlaceholderLink = 'https://czkc.io'
</script>

<template>
  <div
    bg-app-primary w-full h-600px flex flex-col
    lg="h-100vh sticky top-0"
  >
    <div
      flex flex-col gap-6 px-4 pt-4 h-full
      lg="px-8 pt-8"
      xl="px-10 pt-10"
    >
      <div>
        <NuxtImg
          src="/images/logo/CZKC_LOGO_COLOR.svg"
          hidden
          lg="block h-62px"
          xl="mb-12"
        />
      </div>
      <h1
        text-app-secondary text-32px font-bold
        lg:text-48px
      >
        {{ t('descriptionSide.appName') }}
      </h1>
      <ul
        list-none flex flex-col gap-4
        lg="flex-row gap-7.5"
      >
        <li
          v-for="description in descriptions"
          :key="description.key"
          inline-flex items-center gap-3
          lg="flex-col gap-4 items-start"
        >
          <NuxtImg
            :src="description.icon"
            size-6
            lg:size-10
          />
          <span
            text-app-secondary font-medium text-sm
            lg:text-lg
          >
            {{ t(description.key) }}
          </span>
        </li>
      </ul>
    </div>
    <div
      flex flex-col gap-4 px-4 pb-4 h-full
      lg="px-8 pb-8"
      xl="px-10 pb-10"
      bg="coins no-repeat cover xs:contain bottom-left lg:cover xl:contain"
    >
      <div
        text-app-primary flex flex-col font-extrabold w-fit text-5xl mt-auto
        lg:text-7xl
      >
        <span>{{ t('descriptionSide.banner1') }}</span>
        <span>{{ t('descriptionSide.banner2') }}</span>
        <span>{{ t('descriptionSide.banner3') }}</span>
      </div>
      <button button-primary px-6 py-2.5 w-fit gap-3.5>
        <NuxtLink
          text-lg
          :to="learnMorePlaceholderLink"
        >
          {{ t('description.learnMore') }}
        </NuxtLink>
        <UnoIcon class="i-heroicons-arrow-small-right size-6 text-app-accent-primary" />
      </button>
    </div>
  </div>
</template>
