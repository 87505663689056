<script lang="ts" setup>
export interface OptionItem {
  label: string
  value: any
  icon?: string
}

export interface SelectProps {
  name: string
  label?: string
  isSearchable?: boolean
  options: OptionItem[]
  isDisabled?: boolean
  placeholder?: string
  modelValue?: any

  error?: string
  isRequired?: boolean
}

const props = withDefaults(defineProps<SelectProps>(), {
  isSearchable: false,
  isDisabled: false,
  placeholder: 'Vyberte hodnotu',
})

const emit = defineEmits(['update:modelValue', 'blur'])

if (!props.name)
  throw createError('name is required')
if (!props.options)
  throw createError('options are required')

const modelValue = useVModel(props, 'modelValue', emit)

const currentOption = computed(() => props.options.find(option => option.value === modelValue.value))
</script>

<template>
  <div v-auto-animate flex flex-col gap-6px w-full>
    <HeadlessListbox
      v-model="modelValue"
      as="div"
    >
      <div flex flex-col gap-2>
        <HeadlessListboxLabel v-if="label" :id="`headless-listbox-label-${name}`" as="label" label-style>
          {{ label }}
          <span v-if="isRequired" text-app-error>*</span>
        </HeadlessListboxLabel>
        <div relative>
          <HeadlessListboxButton
            :id="`headless-listbox-button-${name}`"
            input-base
            relative cursor-default text-left
            hover:cursor-pointer
          >
            <span v-if="currentOption" flex items-center>
              <NuxtImg
                v-if="currentOption?.icon"
                :src="currentOption.icon"
                alt=""
                size-5 flex-shrink-0 rounded-full
              />
              <span ml-3 block truncate>
                {{ currentOption?.label }}
              </span>
            </span>
            <span
              v-else
              text="app-accent-secondary/50"
            >
              {{ placeholder }}
            </span>
            <span pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2>
              <UnoIcon class="i-heroicons-chevron-down size-5 text-app-accent-secondary/50" aria-hidden="true" />
            </span>
          </HeadlessListboxButton>
          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <HeadlessListboxOptions
              absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 czkc-text-size
              ring="2 app-accent-secondary opacity-5"
              focus:outline-none
            >
              <HeadlessListboxOption
                v-for="option in options"
                :key="option.value"
                v-slot="{ active, selected }"
                as="template"
                :value="option.value"
              >
                <li
                  :class="[active ? 'bg-app-border' : 'text-app-accent-secondary', 'relative cursor-default select-none py-2 pl-3 pr-9']"
                  hover:cursor-pointer
                >
                  <div flex items-center>
                    <NuxtImg
                      v-if="option.icon"
                      :src="option.icon"
                      alt=""
                      size-5 flex-shrink-0 rounded-full
                    />
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
                      {{ option.label }}
                    </span>
                  </div>
                  <span
                    v-if="selected"
                    text-app-accent-secondary absolute inset-y-0 right-0 flex items-center pr-4
                  >
                    <UnoIcon class="i-heroicons-check-solid size-5" aria-hidden="true" />
                  </span>
                </li>
              </HeadlessListboxOption>
            </HeadlessListboxOptions>
          </transition>
        </div>
      </div>
    </HeadlessListbox>
    <div v-if="error" text-app-error>
      {{ error }}
    </div>
  </div>
</template>
