<script setup lang="ts">
const { locale, setLocale } = useI18n()

const langs = useLangs()
</script>

<template>
  <div
    hidden xl:block border="2 app-border" rounded-full flex-none
  >
    <button
      v-for="lang in langs"
      :key="lang.code"
      :class="[
        locale === lang.code
          && `${lang.code === 'cs' ? 'rounded-l-full' : 'rounded-r-full'}
          bg-app-border font-extrabold text-app-accent-secondary/50`,
      ]"
      px-3 py-1 hover:cursor-pointer text-sm
      @click="setLocale(lang.code)"
    >
      {{ lang.name }}
    </button>
  </div>
</template>
