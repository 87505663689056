<script setup lang="ts">
const { locale, setLocale } = useI18n()

const langs = useLangs()
</script>

<template>
  <ul flex flex-col-reverse gap-7 xl:hidden>
    <li
      v-for="lang in langs"
      :key="lang.name"
      :class="[
        locale === lang.code && 'font-extrabold grayscale-0',
      ]"
      inline-flex gap-2 text-lg font-medium grayscale
      hover:cursor-pointer
      @click="setLocale(lang.code)"
    >
      <NuxtImg :src="`/images/flags/${lang.code}.svg`" w-6 />
      <span>{{ lang.mobileName }}</span>
    </li>
  </ul>
</template>
