<script lang="ts" setup>
import { type FieldContext, useField } from 'vee-validate'
import type { InputProps } from './BaseInput.vue'

export type VeeInputProps = InputProps & {
  mode?: string
}

const props = withDefaults(defineProps<VeeInputProps>(), {
  mode: 'eager',
})

const { modes } = useVeeModes()

const { meta, value, errorMessage, handleChange, handleBlur } = useField(
  toRef(props, 'name'),
  value => !!value,
  {
    validateOnValueUpdate: false,
  },
)

// generates the listeners
const handlers = computed(() => {
  const on: Record<string, any> = {
    blur: handleBlur,
    // default input event to sync the value
    // the `false` here prevents validation
    input: [(e: any) => handleChange(e, false)],
  }

  // // Get list of validation events based on the current mode
  const triggers = modes[props.mode]({
    errorMessage: errorMessage as Ref<string | undefined>,
    meta,
  } as FieldContext)

  // // add them to the "on" handlers object
  triggers.forEach(t => {
    if (Array.isArray(on[t]))
      on[t].push(handleChange)
    else
      on[t] = handleChange
  })

  return on
})
</script>

<template>
  <BaseInput
    v-bind="props"
    v-model="value"
    :error="errorMessage"
    v-on="handlers"
  >
    <template #suffix>
      <slot name="suffix" />
    </template>
  </BaseInput>
</template>
