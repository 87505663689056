<script setup lang="ts">
import { QrcodeStream } from 'vue-qrcode-reader'
import { promiseTimeout } from '@vueuse/core'
import type { VeeInputProps } from './BaseInput/Vee.vue'

const props = defineProps<VeeInputProps>()
const emits = defineEmits(['address-changed', 'error-occured'])

const showQrScanner = ref(false)
const loadingCamera = ref(true)
const facingMode = ref<'environment' | 'user'>('environment')
const { t } = useI18n()

const {
  error,
  onError,
  result,
} = useQrCodeRead()

const { ensurePermissions, permissionGranted, isSupported } = useDevicesList()

async function onShowQrScanner () {
  try {
    await ensurePermissions()
    await promiseTimeout(500)
  }
  finally {
    if (permissionGranted.value) {
      loadingCamera.value = true
      showQrScanner.value = true
    }
    else
      emits('error-occured', t('error.permissionDenied'))
  }
}

watch(error, () => {
  if (error.value) {
    showQrScanner.value = false
    emits('error-occured', error.value)
  }
})

watch(result, () => {
  if (!result.value)
    return
  emits('address-changed', result.value[0])
  showQrScanner.value = false
})

function switchCamera () {
  facingMode.value = facingMode.value === 'environment' ? 'user' : 'environment'
}
</script>

<template>
  <div>
    <HeadlessTransitionRoot appear :show="showQrScanner" as="template">
      <HeadlessDialog>
        <HeadlessTransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div fixed inset-0 bg="black/25" />
        </HeadlessTransitionChild>
        <div fixed inset-0 overflow-y-auto>
          <div flex min-h-full items-center justify-center p-16px text-center>
            <HeadlessTransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <HeadlessDialogPanel w-full max-w-md transform overflow-hidden rounded-2xl bg-white pb-16px pt-12px px-24px text-left shadow-xl transition-all>
                <HeadlessDialogTitle as="h3" text-lg font-medium leading-24px text-gray-900 mb-16px>
                  Qr Scanner
                  <button type="button" absolute right-16px top-12px @click="showQrScanner = false">
                    <UnoIcon i-heroicons-x-mark text="24px sm:28px" />
                  </button>
                </HeadlessDialogTitle>
                <QrcodeStream
                  v-if="!error && showQrScanner"
                  :constraints="{ facingMode }"
                  rounded-4px border="app-border 1px" rounded-xl
                  @error="onError" @camera-on="loadingCamera = false"
                >
                  <p v-if="loadingCamera" text-black w-full h-full flex items-center justify-center>
                    <UnoIcon i-svg-spinners-180-ring text="32px app-primary" />
                  </p>
                  <button absolute right-8px bottom-8px type="button" class="rounded-lg bg-app-primary p-1 text-white shadow-sm hover:bg-app-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-app-primary" @click="switchCamera()">
                    <UnoIcon i-icon-park-solid-flip-camera text-24px aria-hidden="true" />
                  </button>
                </QrcodeStream>
              </HeadlessDialogPanel>
            </HeadlessTransitionChild>
          </div>
        </div>
      </HeadlessDialog>
    </HeadlessTransitionRoot>

    <BaseInputVee
      v-bind="props"
    >
      <template #suffix>
        <ClientOnly>
          <button v-if="isSupported" type="button" flex items-center gap-4px text="16px app-primary" font-medium @click="onShowQrScanner()">
            <UnoIcon i-heroicons-qr-code text="24px sm:28px" />
            Scan
          </button>
        </ClientOnly>
      </template>
    </BaseInputVee>
  </div>
</template>
