<script lang="ts" setup generic="T">
import { type HTMLAttributes } from 'vue'

export interface FRadioGroupProps {
  class?: HTMLAttributes['class']
  name?: string
  modelValue?: any
  error?: string
}

const props = defineProps<FRadioGroupProps>()

const emit = defineEmits(['update:modelValue', 'blur'])
const modelValue = useVModel(props, 'modelValue', emit)
</script>

<template>
  <ClientOnly>
    <HeadlessRadioGroup v-model="modelValue" :class="props.class">
      <slot />
    </HeadlessRadioGroup>
    <div v-if="error" text-app-error>
      {{ error }}
    </div>
  </ClientOnly>
</template>
